import { template as template_e1335b11c3704c9285942a2c946bb6d7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_e1335b11c3704c9285942a2c946bb6d7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
