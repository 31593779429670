import { template as template_b55dbf830f3f49d6b59f58de9f04775c } from "@ember/template-compiler";
const FKText = template_b55dbf830f3f49d6b59f58de9f04775c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
