import { template as template_32d55ae5d9034acbb2498ed678d3f697 } from "@ember/template-compiler";
const WelcomeHeader = template_32d55ae5d9034acbb2498ed678d3f697(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
